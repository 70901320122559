export const reports = {
  FB_MESSENGER: 'FB_MESSENGER'
};

export const screens = {
  DROPFILES: 'DROPFILES',
  LOADING: 'LOADING',
  DASHBOARD: 'DASHBOARD',
  INSTRUCTIONS: 'INSTRUCTIONS'
};

export const mainColor = '#8F00FF';
