/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';

import * as Actions from '../state/actions';
import { reports, screens } from '../constants';

import ViewFbMessenger from '../plugins/FB_MESSENGER/view';

function goGithub() {
  if (window) window.open('https://www.github.com/melalj/explorata');
}

function goExplorata() {
  if (window) window.open('https://explorata.io');
}

class Dashboard extends React.Component {
  getReportView() {
    const { currentReport } = this.props;
    if (currentReport === reports.FB_MESSENGER) {
      return <ViewFbMessenger />;
    }
    return null;
  }

  render() {
    const { currentReport, goDropfiles, isLoading } = this.props;
    if (isLoading) return null;
    return (
      <div className="page">
        <div>
          <div style={{ float: 'right' }}>
            <Button onClick={() => goGithub()}>Contribute on Github</Button>
            <Button onClick={() => goDropfiles()}>Upload new dataset</Button>
          </div>
          <p>
            Facebook Messenger Report generated by
            <a onClick={() => goExplorata()}>
              {' '}
              Explorata.io
            </a>
          </p>
        </div>
        {!currentReport ? null : this.getReportView()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentReport: state.currentReport,
    isLoading: state.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      goDropfiles: () => Actions.setCurrentScreen(screens.DROPFILES),
      setLoading: d => Actions.setLoading(d),
      setError: e => Actions.setError(e)
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
